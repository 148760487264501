const workspace = ['default', 'live'];

const consentStatus = {
    GRANTED: 'granted',
    DENIED: 'denied'
    // REVOKED:"REVOKED"
};
const events = {
    TOGGLE_SHOW_DETAILS: '_jcm_ToggleShowDetails'
};
export {
    workspace,
    consentStatus,
    events
};
